import React from 'react';
import "./OffreDecouverte.css";

const prices = <>
    <table border="1">
        <thead>
        <tr>
            <th>Service</th>
            <th>Tarif Original</th>
            <th>Tarif Réduit</th>
            <th>Durée</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>1 séance Balnéo</td>
            <td><s>35 €</s></td>
            <td>15 €</td>
            <td>20 min</td>
        </tr>
        <tr>
            <td>1 séance Dôme japonais</td>
            <td><s>30 €</s></td>
            <td>10 €</td>
            <td>30 min</td>
        </tr>
        <tr>
            <td>1 séance Balnéo + Dôme japonais (1 personne)</td>
            <td><s>65 €</s></td>
            <td>25 €</td>
            <td>50 min</td>
        </tr>
        <tr>
            <td>1 séance Balnéo + Dôme japonais (2 personnes)</td>
            <td><s>110 €</s></td>
            <td>50 €</td>
            <td>50 min</td>
        </tr>
        </tbody>
    </table>

    <h3>Forfait Dôme japonais</h3>
    <table border="1">
        <thead>
        <tr>
            <th>Nombre de séances</th>
            <th>Tarif Original</th>
            <th>Tarif Réduit</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>6 séances</td>
            <td><s>170 €</s></td>
            <td>60 €</td>
        </tr>
        <tr>
            <td>15 séances</td>
            <td><s>410 €</s></td>
            <td>150 €</td>
        </tr>
        <tr>
            <td>20 séances</td>
            <td><s>530 €</s></td>
            <td>200 €</td>
        </tr>
        </tbody>
    </table>

    <h3>Forfait Balnéo</h3>
    <table border="1">
        <thead>
        <tr>
            <th>Nombre de séances</th>
            <th>Tarif Original</th>
            <th>Tarif Réduit</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>1 séance/sem sur 1 mois (4 séances)</td>
            <td><s>120 €</s></td>
            <td>60 €</td>
        </tr>
        </tbody>
    </table>
</>

function OffreDecouverte(props) {
    return (
        <div className={"OffreDecouverte"}>
            <h1>Accès à l'<span>Espace Privatif</span> à partir de <span>10€</span> !</h1>
            {prices}

            <h1>Découvrez les Secrets du Dôme Japonais et de la Balnéothérapie</h1>
            <p>
                Le dôme japonais et la balnéothérapie sont des alliés précieux pour votre bien-être. Le dôme japonais,
                grâce à sa technologie de chaleur infrarouge, permet une détoxification en profondeur, favorise la
                relaxation musculaire et améliore la circulation sanguine, pour une sensation de légèreté et de
                revitalisation.
                La balnéothérapie, quant à elle, utilise les bienfaits de l'eau pour détendre les tensions, soulager
                les douleurs articulaires et offrir un moment de détente incomparable. Ensemble, ces soins contribuent
                à harmoniser le corps et l'esprit, pour une expérience unique de sérénité et de ressourcement.
            </p>

            <h1>Détente et Bien-Être en Solo</h1>
            <p>
                Offrez-vous une parenthèse de bien-être rien que pour vous ! Nos séances individuelles de balnéothérapie
                et de dôme japonais sont conçues pour vous procurer un moment de détente profonde et de revitalisation.
                Que vous choisissiez une séance de balnéo pour apaiser vos muscles ou le dôme japonais pour une
                détoxification intense, vous ressortirez de chaque séance avec un esprit léger et un corps reposé.
                Profitez également de nos offres spéciales pour vous accorder plusieurs séances à un tarif réduit.
                Prenez du temps pour vous et découvrez les bienfaits d’un instant de sérénité absolue.
            </p>

            <h1>Partagez un Instant de Complicité et de Détente</h1>
            <p>
                Vivez une expérience de bien-être à deux ! Nos séances combinées de balnéothérapie et de dôme japonais
                pour les couples sont parfaites pour partager un moment de relaxation intense et de complicité.
                En 50 minutes, profitez ensemble des effets apaisants de la balnéo et de la chaleur bienfaisante du
                dôme japonais, un duo parfait pour se ressourcer et renforcer les liens. Bénéficiez de tarifs réduits
                pour vous offrir cette parenthèse unique à deux, et laissez-vous emporter dans une expérience
                sensorielle qui vous rapprochera encore davantage.
            </p>
        </div>
    );
}

export default OffreDecouverte;