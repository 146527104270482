import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper modules
import { Navigation, Autoplay } from "swiper/modules";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import "./Carousel.css";

function Carousel({ props: images }) {

    return (
        <div className="slider-container">
            <Swiper
                modules={[Navigation, Autoplay]}
                spaceBetween={15}
                slidesPerView={"auto"}
                centeredSlides={true}
                centeredSlidesBounds={true}
                loop={images && images.length > 1}
                navigation
                autoplay={{
                    delay: 2000,
                    pauseOnMouseEnter: true,
                }}
            >
                {images && images.map((image, index) => {
                    return (
                        <SwiperSlide key={index} style={{ width: 'auto' }} zoom={true}>
                            <img src={image.src} alt={image.alt} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}

export default Carousel;
