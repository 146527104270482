import Logo from '../logo.png'
import './Footer.css'

function Footer() {
  return (
    <div className="Footer">
      <div className='box-left'>
        <h1>Contact</h1>
        <p><a target={`_blank`} href="http://maps.google.com/?q=37bis Av. Jean Jaurès 89400 Migennes">37bis Av. Jean Jaurès 89400 Migennes</a></p>
        <p><a href="tel:+33386918258">03 86 91 82 58</a></p>
        <p><a href="mailto:contact-migennes@azoya-spa.com">contact-migennes@azoya-spa.com</a></p>
      </div>
      <div className='box-center'>
        <img src={Logo} alt="Logo" />
      </div>
      <div className='box-right'>
        <h1>Horaires</h1>
        <p>Ouvert 4/7 jours</p>
        <p>Lundi - Jeudi - Vendredi - Samedi</p>
        <p>Mardi, Mercredi et Dimanche : Fermé</p>
      </div>
    </div>
  );
}

export default Footer;