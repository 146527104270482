import { useEffect } from 'react'
import photo_massage_duo from './image1.webp'
import photo_salle_solo from './image2.webp'
import photo_accueil from './image3.webp'
import photoCarousel1 from './image4.webp'
import photoCarousel2 from './image5.webp'
import photoCarousel3 from './image6.webp'
import photoCarousel4 from './image7.webp'
import './Accueil.css'
import Carousel from "../../components/carousel/Carousel";

function Accueil() {
  useEffect(() => {
    document.title = "Accueil - Azoya SPA Migennes";
  }, [])

  return (
    <>
      <div className="slide-container">
        <div className="slide-center">
          <div className="slide-text-content">
            <h1>Un <em>moment de détente</em> et de bien être en couplant <em>Dôme Japonais et Balnéo</em></h1>
            <p>
              Passez d'une chaleur enveloppante du dôme à la douceur de l'eau chaude de la Balnéo pour une expérience
              sensorielle unique et relaxante.
            </p>
          </div>
            <Carousel props={[
              {src: photoCarousel1, alt: "Image4"},
              {src: photoCarousel2, alt: "Image5"},
              {src: photoCarousel3, alt: "Image6"},
              {src: photoCarousel4, alt: "Image7"},
            ]}/>

        </div>

          <div className="slide slide-left">
            <div className="slide-text-content">
              <h1>Un <em>espace de détente</em> situé <em>au coeur</em> de Migennes</h1>
              <p>
                Profitez de différentes façons de prendre soin de votre corps et de vous relaxer au sein de notre
                structure.
                Des professionnels qualifiés sont présents pour vous accompagner dans votre démarche de bien-être.
              </p>
            </div>
            <div className="slide-image-box">
              <img src={photo_salle_solo} alt="table de massage seul"/>
            </div>
          </div>
          <div className="slide slide-center">
            <div className="slide-text-content">
              <h1><em>Soins et Massages de qualité</em> pour profiter seul ou à deux 👩🏻‍🤝‍👨🏽</h1>
              <p>
                Que ce soit pour des massages ou des soins du visage vous pouvez vous détendre
                en toute confiance et profiter d'une expérience agréable. L'institut met tout en œuvre pour vous offrir
                un moment
                de relaxation, afin que vous puissiez vous sentir bien dans votre peau.
              </p>
            </div>
            <div className="slide-image-box">
              <img src={photo_massage_duo} alt="table massage en duo"/>
            </div>
          </div>
          <div className="slide slide-right">
            <div className="slide-text-content">
              <h1>Des produits <em>Baïja</em> dédiés à prendre soin de vous</h1>
              <p>
                Vous pourrez trouver à l'accueil tous les produits nécessaires pour prendre soin de vous à la maison
                (gommages, crèmes, masques, huiles, gels douche, etc...).<br/>
                Mais aussi des produits doux et relaxant tel que des bougies, des bouquets parfumés et bien
                d'autres.<br/>
                N'hésitez pas à prendre conseil auprès de votre esthéticienne.
              </p>
            </div>
            <div className="slide-image-box">
              <img src={photo_accueil} alt="accueil institut avec les produits en ventes"/>
            </div>
          </div>
        </div>
      </>
      );
      }

      export default Accueil;