import {Route, Routes} from 'react-router-dom'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Error404 from './pages/Error404/Error404'
import ReserverEnLigne from './pages/reserver-en-ligne/ReserverEnLigne'
import OffresAbonnement from './pages/OffresAbonnement/OffresAbonnement'
import Accueil from './pages/Accueil/Accueil'
import PlanityAccount from './components/planity/Account/PlanityAccount'
import PlanityGift from './components/planity/Gift/PlanityGift'
import Services from './pages/Services/Services'
import OffreDecouverte from "./pages/Appart/OffreDecouverte";

function App() {

    return (
        <div className="App">
            <Header/>
            <div className="main">
                <div className="container" id='container'>
                    <Routes>
                        <Route path='/' element={<Accueil/>}/>
                        <Route path='/accueil' element={<Accueil/>}/>
                        <Route path='/offres-abonnement' element={<OffresAbonnement/>}/>
                        <Route path='/team' element={<Error404/>}/>
                        <Route path='/services' element={<Services/>}/>
                        <Route path='/reserver-en-ligne' element={<ReserverEnLigne/>}/>


                        {/* Pages dédiées au service Planity */}
                        <Route path='/offrir' element={<PlanityGift/>}/>
                        <Route path='/mon-compte' element={<PlanityAccount/>}/>
                        {/* ==================================================== */}

                        <Route path={"/offre-decouverte-dome-balneo"} element={<OffreDecouverte/>}/>

                        <Route path='*' element={<Error404/>}/>
                    </Routes>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
